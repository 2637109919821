import * as Sentry from '@sentry/gatsby';
import axios, { AxiosRequestConfig } from 'axios';

// strapi
export interface ICreateContactRequestStrapi {
  date: string;
  name: string;
  message: string;
  email: string;
  phone: string;
  nda: boolean;
  context: string;
}
export interface ISubscribeStrapi {
  date: string;
  email: string;
  recaptcha_token: string;
}
export interface ICreateFitnessAppRequestStrapi {
  name: string;
  email: string;
  phone: string | null;
  integration: string | null;
  message: string;
  date: string;
  recaptcha_token: string;
}

export interface ICreatePositionRequestStrapi {
  date: string;
  full_name: string;
  email: string;
  chosen_position: string;
  message: string | null;
  file_cv?: string | null | undefined;
  // recaptcha_token: string;
}

const strapiAxiosInstance = axios.create({
  baseURL: process.env.GATSBY_STRAPI_URL,
});

const strapiInstance = (props: AxiosRequestConfig) => {
  return strapiAxiosInstance(props).catch((err) => {
    Sentry.withScope(function (scope) {
      scope.setFingerprint([
        'strapi',
        `method: ${props?.method}`,
        `api url: ${props?.url}`,
        `data: ${JSON.stringify(props.data)}`,
      ]);
      Sentry.captureException(err);
    });
    throw err;
  });
};

export const api = {
  strapi: {
    createContactRequest: (data: ICreateContactRequestStrapi) =>
      strapiInstance({
        method: 'post',
        data,
        url: '/request-contacts',
      }),
    subscribe: (data: ISubscribeStrapi) =>
      strapiInstance({
        method: 'post',
        data,
        url: '/request-subscribes',
      }),
    createFitnessAppRequest: (data: ICreateFitnessAppRequestStrapi) =>
      strapiInstance({
        method: 'post',
        data,
        url: '/request-fitness-apps',
      }),
    createFitnessAppEstimation: (data: any) =>
      strapiInstance({
        method: 'post',
        data,
        url: '/fitness-app-calculators',
      }),
    updateFitnessAppEstimation: (id: string | number, data: any) =>
      strapiInstance({
        method: 'put',
        data,
        url: `/fitness-app-calculators/${id}`,
      }),
    getFitnessAppEstimation: (id: string | number) =>
      strapiInstance({
        method: 'get',
        url: `/fitness-app-calculators/${id}`,
      }),
    createPositionRequest: (data: ICreatePositionRequestStrapi) =>
      strapiInstance({
        method: 'post',
        data,
        url: `/request-positions`,
      }),
    upload: (data: any) =>
      strapiInstance({
        method: 'post',
        data,
        url: '/upload',
      }),
  },
};

const calendlyAxiosInstance = axios.create({
  baseURL: 'https://api.calendly.com/',
});

const calendlyInstance = (props: AxiosRequestConfig) => {
  return calendlyAxiosInstance(props).catch((err) => {
    Sentry.withScope(function (scope) {
      scope.setFingerprint([
        'calendly',
        `method: ${props?.method}`,
        `api url: ${props?.url}`,
        `data: ${JSON.stringify(props.data)}`,
      ]);
      Sentry.captureException(err);
    });
    throw err;
  });
};

calendlyAxiosInstance.interceptors.request.use(
  (config) => {
    const auth = `Bearer ${process.env.GATSBY_CALENDLY_TOKEN}`;
    config.headers.common['Authorization'] = auth;
    config.headers.common['Content-Type'] = 'application/json';
    return config;
  },
  (error) => Promise.reject(error)
);

export const calendlyApi = {
  getEventInvitee: (url: string) =>
    calendlyInstance({
      method: 'GET',
      url,
    }),
};

const hubspotAxiosInstance = axios.create({
  baseURL: 'https://api.hubapi.com/',
});

hubspotAxiosInstance.interceptors.request.use(
  (config) => {
    const auth = `Bearer ${process.env.GATSBY_HS_TOKEN}`;
    config.headers.common['Authorization'] = auth;
    return config;
  },
  (error) => Promise.reject(error)
);

export const addHsProperty = ({ userEmail, strapiId }: { userEmail: string; strapiId: string }) =>
  hubspotAxiosInstance.post(`/contacts/v1/contact/createOrUpdate/email/${userEmail}`, {
    properties: [
      {
        property: 'strapi_id',
        value: strapiId,
      },
    ],
  });
